import $ from "jquery";

const tr_link = () => {
  $("tr[data-link]").on("click", function (e) {
    if (e.target.tagName.toLowerCase() == "i") {
      return
    } else if (e.target.tagName.toLowerCase() !== "a") {
      window.location = $(this).data("link");
    }
  });
};

export { tr_link };
